'use strict'

/**
 * Constants file with the endpoints used by recover password module to call a REST-services of the backend API.
 *
 * @type {number}
 *
 * @author Andres Correa
 * @since 18/05/2020 09:57 PM
 * @version 1.0.0
 */

/* Backend endpoints */
const PUBLIC_BASE_URL = process.env.VUE_APP_API_URL
export const CONFIRM_CHANGE_PASSWORD = `${PUBLIC_BASE_URL}service/user`
