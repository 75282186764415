'use strict'

/**
 * Object used to validate the password form.
 *
 * @author Andres Correa
 * @since 15/05/2020 12:15 M
 * @version 1.0.0
 */
export default {
  password: {
    required: true,
    min: 8,
    max: 50,
    password_format: true
  },
  confirm_password: {
    required: true,
    min: 8,
    max: 50,
    password_format: true,
    confirmed: 'password'
  }
}
