<template>
  <v-content>
    <v-container
      fluid
    >
      <v-row
        align="start"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <v-card
            color="quaternary"
            flat
          >
            <v-toolbar
              class="pb-0"
              color="quaternary"
              flat
            >
              <v-toolbar-title>{{ $t('recover_password') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <ValidationObserver
              ref="obs"
              v-slot="{ passes }"
            >
              <v-form
                @submit.prevent="passes(changePassword)"
              >
                <div>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('password')"
                    :rules="ValidationsChangePassword.password"
                    vid="password"
                  >
                    <v-text-field
                      id="password"
                      v-model="sendModel.password"
                      :error-messages="errors"
                      name="password"
                      :label="$t('password')"
                      type="password"
                      background-color="white"
                      outlined
                      required
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$t('confirm_new_password')"
                    :rules="ValidationsChangePassword.confirm_password"
                    vid="confirmPassword"
                  >
                    <v-text-field
                      id="confirmPassword"
                      v-model="sendModel.confirmPassword"
                      :error-messages="errors"
                      name="confirmPassword"
                      :label="$t('confirm_new_password')"
                      type="password"
                      background-color="white"
                      outlined
                      required
                    />
                  </ValidationProvider>
                </div>
                <v-card-actions>
                  <v-btn
                    id="btnChangePassword"
                    color="primary secondary--text"
                    type="submit"
                    block
                  >
                    {{ $t('enterokay') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import handleErrorMixin from '../../../../../mixins/handleErrorMixin'
import authenticationMixin from '../../../../../mixins/authenticationMixin'
import { CONFIRM_CHANGE_PASSOWORD } from '../../../../../store/modules/security/mutations-types'
import ValidationsChangePassword from './data/validationsChangePassword'
import { mapState } from 'vuex'
import { SHOW_ALERT } from '@/store/mutations-types'
import { NOT_FOUND } from '@/shared/constants/httpStatus'
import { CONFIRM_CHANGE_PASSWORD } from './data/changePassword'
/**
   * Component to confirm change password
   *
   * @author Andres Correa
   * @version 1.0.0
   * @since 05/02/2020 3:15 p. m.
   */
export default {
  name: 'ConfirmChangePassword',
  components: {
    // AppPassword
  },
  mixins: [handleErrorMixin, authenticationMixin],
  data () {
    return {
      sendModel: {
        password: null
      },
      ValidationsChangePassword
    }
  },
  computed: {
    /**
     * This property computed get userInfo objet that response back
     *
     * @author Andres Correa
     * @since 18/05/2020 3:20 PM
     * @version 1.0.0
     */
    ...mapState({ userId: (state) => state.security.userInfo.businessUserDetailDTO.userId })
  },
  methods: {
    /**
        *This method send to back the new password
        *
        * @author Andres Correa
        * @version 1.0.0
        * @since 18/05/2020 5:19 p. m.
    */
    changePassword () {
      this.sendModel.userId = this.userId
      this.axios.put(`${CONFIRM_CHANGE_PASSWORD}`, this.sendModel)
        .then(response => {
          this.$store.commit(CONFIRM_CHANGE_PASSOWORD, false)
          this.$router.push({ name: 'Home' })
          this.$store.commit(SHOW_ALERT, {
            type: 'success',
            text: this.$t('change_success'),
            show: true
          })
        })
        .catch(error => {
          if (error.response.status === NOT_FOUND) {
            this.$store.commit(SHOW_ALERT, {
              type: 'error',
              text: this.$t('wrong_password'),
              show: true
            })
            return
          }
          this.$_handleErrorMixin_generateError(error)
        })
    }
  }
}
</script>
<i18n>
{
  "es": {
    "confirm_new_password": "Confirmar nueva contraseña",
    "enterokay": "CAMBIAR CONTRASEÑA",
    "password": "Contraseña",
    "recover_password": "CAMBIAR CONTRASEÑA",
    "wrong_password": "Usuario no existente",
    "change_success": "Cambio de contraseña exitoso"
  }
}
</i18n>
